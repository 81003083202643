var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-date-picker',{attrs:{"select-attribute":_vm.attr,"update-on-input":false,"is-required":"","is-dark":_vm.checkMode,"highlight":{
    style: {
      position: 'relative',
    },
  },"popover":{
    keepVisibleOnInput: true,
  }},on:{"input":function($event){_vm.currentArticle = null}},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('input',_vm._g({staticClass:"bg-white border px-2 py-1 rounded moder-publications-calendar__input",attrs:{"readonly":""},domProps:{"value":inputValue}},inputEvents))]}},{key:"day-popover",fn:function({}){return [_c('div',{staticClass:"popover-calendar__content"},[_c('div',{staticClass:"modal-date"},[_c('div',{staticClass:"modal-date__content"},[_c('div',{staticClass:"modal-date__top"},[_c('p',{staticClass:"modal-date__count"},[_vm._v("16 публикаций")]),_c('p',{staticClass:"modal-date__count"},[_vm._v("12")])]),_c('div',{staticClass:"modal-date__body"},[_c('ul',{staticClass:"modal-date__list"},_vm._l((5),function(i){return _c('li',{key:i,staticClass:"modal-date__item",on:{"click":function($event){_vm.currentArticle == _vm.getArticle(i)
                    ? (_vm.currentArticle = null)
                    : (_vm.currentArticle = _vm.getArticle(i))}}},[_c('p',{staticClass:"modal-date__title"},[_vm._v(" Появится ли общая Европейская армия? Часть 2 ")]),_c('p',{staticClass:"modal-date__author"},[_vm._v("Ульянова Анна")]),(_vm.currentArticle && i == _vm.currentArticle.id)?_c('Modal',{staticClass:"publications-modal_top",style:({ top: '200%' }),attrs:{"currentArticle":_vm.currentArticle,"classOfParent":'modal-date__item',"position":'top'},on:{"closeModal":function($event){_vm.currentArticle = null}}}):_vm._e()],1)}),0)])])])])]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})
}
var staticRenderFns = []

export { render, staticRenderFns }